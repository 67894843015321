import { StrictMode } from 'react';
import { render } from 'react-dom';
import App from './app/App';
import { register } from './serviceWorkerRegistration';

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register();
